require("react");
import Link from "next/link";
import Slider from "react-slick";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import InfiniteScroll from 'react-infinite-scroll-component';
import StackGrid from "react-stack-grid";
import ClipLoader from "react-spinners/ClipLoader";
import MasterSliderImages from "../components/MasterSliderImages/MasterSliderImages";
import MainLayout from "../Layouts/MainLayout";
import Comments from "../components/Comments/Comments";
import hooks from "../hooks/homeHooke";
import cookies from 'next-cookies';
import styles from '../styles/Home.module.scss';
import { wrapper } from '../store';
import { useState } from "react";


const queryString = require('query-string');
export const getServerSideProps = wrapper.getServerSideProps(async (ctx) => {
    const { token, lat, lng } = cookies(ctx);
    const { locale } = ctx;
    let query = queryString.stringify(ctx.query) ? queryString.stringify(ctx.query) : "";

    return {
        props: {
            ...(await serverSideTranslations(locale, ["common"])),
            token: token || '',
            query,
            lat: lat ? lat : '',
            lng: lng ? lng : '',
            loggedIn: true,
            language: locale ? locale : 'fr'
        }
    };
});

function HomePage(props) {
    const { t } = useTranslation();
    const [onLayout, setOnLayout]=useState();
    // const { posts } = props;
    // const company = useSelector(state => state.company);
    const {
        posts,
        popular_master,
        // translate,
        mybutton,
        user,
        posts_slider,
        router,
        get_new_post,
        posts_loader,
        set_post_comment,
        add_child_comment,
        set_child_comment,
        add_comment,
        get_comments,
        hide_post,
        report_post,
        infiniteScroll,
        setMybutton,
    } = hooks(props);
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        initialSlide: 'ppp',
        arrows: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    arrows: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    arrows: false,
                }
            }
        ]
    };
    const settingsFirstSlider = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 'ppp',
        arrows: true,
    };
    const settingsSecondSlider = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 'ppp',
        arrows: true,
    };
    const canUseDOM = !!(
        (typeof window !== 'undefined' &&
            window.document && window.document.createElement)
    );
    return (
        <div
            className="home-page"
            id="scrollableDiv"
            style={{
                height: "100vh",
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column',
            }}
        >

            <div id="toTop"></div>
            {/* {posts.loading ? <div className={styles.see_more}>
                <ClipLoader color="#464646" size={15} />
            </div> : null} */}
            <InfiniteScroll
                dataLength={posts.counts ? posts.counts : 10}
                style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
                inverse={true} //
                hasMore={true}
                // loader={
                //     <div className={styles.see_more}>
                //         <ClipLoader color="#464646" size={15} />
                //     </div>
                // }
                scrollableTarget="scrollableDiv"
                onScroll={(e) => {
                    if (e.target.scrollTop > 20) {
                        setMybutton(true);
                    } else {
                        setMybutton(false);
                    }
                    if ((parseInt(e.target.clientHeight + e.target.scrollTop) !== e.target.scrollHeight)
                        && (parseInt(e.target.clientHeight + e.target.scrollTop + 1) !== e.target.scrollHeight)) return;
                    posts_loader()
                    if (posts.more_loading) {
                        infiniteScroll();
                    }
                }}
            >
                <MainLayout
                    meta={t("posts_title")}
                    title={t("posts_descriptions")}
                    image=""
                    url=""
                    token={props.token}
                    user_image={user ? user.image : ""}
                    id={user ? user.id : ""}
                    language={props.language}
                // onChange={(value) => get_new_post({
                //     hash_tag: value,
                //     token: props.token,
                //     ...props.query,
                //     lat: props.lat,
                //     long: props.lng,
                //     offset: 0,
                //     limit: 10
                // })}
                >
                    <main className={styles.home}>
                        <div className="container">
                            <div className="">
                                {/*  <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                           <Link as={`/${props.language}`} href={`/${props.language}`}>
                                               <a>{t("main")}</a>
                                           </Link>
                                       </li>
                                     <li className="breadcrumb-item active" aria-current="page">
                                         {t("timelineLabel")}
                                    </li>
                                
                                  </ol>
                                </nav> */}
                                {(posts_slider?.is_article?.length > 0 || posts_slider?.no_article?.length > 0) &&
                                    <div className="w-100 home-slider-cont article-slider-cont">
                                        {posts_slider?.is_article && posts_slider?.is_article.length > 0 && <Slider {...settingsFirstSlider}>
                                            {posts_slider?.is_article?.map((item, index) => {
                                                return <div
                                                    key={index}
                                                    onClick={() => router.push(`/${props.language}/article/${item?.id}`)}
                                                    className={styles.homeSliderImageContainer}>
                                                    <img src={item?.main_image} className={styles.homeSliderImage} />
                                                    <div className={styles.containerTextButton}>
                                                        <h1 className={styles.heading}>{item?.title}</h1>
                                                    </div>
                                                </div>
                                            })}
                                        </Slider>}
                                        {posts_slider?.no_article && posts_slider?.no_article.length > 0 && <Slider {...settingsSecondSlider}>
                                            {posts_slider?.no_article?.map((item, index) => {
                                                return <div
                                                    key={index}
                                                    onClick={() => router.push(`/${props.language}/article/${item?.id}`)}
                                                    className={styles.homeSliderSecondImageContainer}>
                                                    <img src={item?.main_image} className={styles.homeSliderImage} />
                                                    <div className={styles.containerTextButton}>
                                                        <h1 className={styles.heading}>{item?.title}</h1>
                                                    </div>
                                                </div>
                                            })}
                                        </Slider>}
                                    </div>
                                }
                                {/*  <h2>{t("timehomemasters")}</h2> */}
                                {/* <div className={styles.map_link}>
                                    <Link as="/map" href="/map">
                                        <a className="home-map-page d-flex align-items-center justify-content-end">
                                            <i className="icon-map-label"></i>
                                            <span>{t("onMapLabel")}</span>
                                        </a>
                                    </Link>
                                </div> */}
                            </div>
                            {/* <p className={styles.slider_top}>{t("popularLabel")}</p> */}
                            <div className="w-100 home-slider-cont">
                                <Slider {...settings}>
                                    {!popular_master.loading && popular_master.results ?
                                        popular_master.results.map((item, index) => {
                                            return <MasterSliderImages
                                                key={index}
                                                url={`/${props.language}/master/${item.id}`}
                                                src={item.image ? item.image : "/profile_avatar_default.png"}
                                                text={item.user.first_name && item.user.last_name
                                                    ? `${item.user.first_name} ${item.user.last_name}`
                                                    : (item.user.username ?? item.user.username)} />;
                                        }) : null}
                                </Slider>
                            </div>
                            <div className={styles.forWeb}>
                                {posts.results.length === 0 && <div className='empty_text'>{t('emptyTimelinePostsLabel')}</div>}
                                {posts && posts.results && canUseDOM && window.innerWidth ? <StackGrid
                                    columnWidth={canUseDOM && window.innerWidth <= 992 ? '48%' : '30%'}
                                    gap={10}
                                    className='StackGridCont'
                                    duration={500}
                                    vendorPrefix={true}
                                    monitorImagesLoaded={true}
                                    // onLayout={()=>{
                                    //     console.log('in onLayout');
                                    //     setOnLayout(true)}}
                                >{
                                        posts.results.map((item, index) => {
                                            if (item.user_details.is_master) {
                                                return <Comments
                                                    key={index}
                                                    item={item}
                                                    language={props.language}
                                                    get_post={(text) => get_new_post({
                                                        hash_tag: text,
                                                        token: props.token,
                                                        ...props.query,
                                                        lat: props.lat,
                                                        long: props.lng,
                                                        offset: 0,
                                                        limit: 10
                                                    })}
                                                    loggedIn={props.loggedIn}
                                                    my_image={user ? user.image : ""}
                                                    token={props.token}
                                                    onChange={(value) => set_child_comment({
                                                        post: item.id,
                                                        comment: value
                                                    })}
                                                    onChangePostComment={(value) => set_post_comment({
                                                        post: item.id,
                                                        comment: value
                                                    })}
                                                    onClick={(id) => add_child_comment({
                                                        comment_id: id,
                                                        post_id: item.id,
                                                        token: props.token
                                                    })}
                                                    propsClickPostComment={() => {
                                                        add_comment({
                                                            comment: item.new_comment_value,
                                                            post_id: item.id,
                                                            token: props.token
                                                        });
                                                    }}
                                                    hide_post={() => hide_post({
                                                        id: item.id,
                                                        token: props.token
                                                    })}
                                                    report_post={(text) => report_post({
                                                        text,
                                                        post: item.id,
                                                        user: user.id,
                                                        token: props.token
                                                    })}
                                                    get_comments={() => {
                                                        get_comments({ post_id: item.id, token: props.token });
                                                    }}
                                                    get_comments_more={() => get_comments({
                                                        post_id: item.id,
                                                        more_loading: true,
                                                        token: props.token
                                                    })}
                                                    comments={item?.comments}
                                                />
                                            }
                                        })
                                    }
                                </StackGrid> : null}
                                {(posts.more_loading && posts.results.length > 0) || posts?.loading && <div className={styles.see_more}>
                                    <ClipLoader color="#464646" size={15} />
                                </div>}
                            </div>
                            <div className={styles.forMobile}>
                                {posts.results.length === 0 && <div className='empty_text'>{t('emptyTimelinePostsLabel')}</div>}
                                {posts && posts.results ? posts.results.map((item, index) => {
                                    if (item.user_details.is_master) {
                                        return <Comments
                                            key={index}
                                            item={item}
                                            language={props.language}
                                            get_post={(text) => get_new_post({
                                                hash_tag: text,
                                                token: props.token,
                                                ...props.query,
                                                lat: props.lat,
                                                long: props.lng,
                                                offset: 0,
                                                limit: 10
                                            })}
                                            loggedIn={props.loggedIn}
                                            my_image={user ? user.image : ""}
                                            token={props.token}
                                            onChange={(value) => set_child_comment({
                                                post: item.id,
                                                comment: value
                                            })}
                                            onChangePostComment={(value) => set_post_comment({
                                                post: item.id,
                                                comment: value
                                            })}
                                            onClick={(id) => add_child_comment({
                                                comment_id: id,
                                                post_id: item.id,
                                                token: props.token
                                            })}
                                            propsClickPostComment={() => {
                                                add_comment({
                                                    comment: item.new_comment_value,
                                                    post_id: item.id,
                                                    token: props.token
                                                });
                                            }}
                                            hide_post={() => hide_post({
                                                id: item.id,
                                                token: props.token
                                            })}
                                            report_post={(text) => report_post({
                                                text,
                                                post: item.id,
                                                user: user.id,
                                                token: props.token
                                            })}
                                            comments={item?.comments}
                                            get_comments={() => {
                                                get_comments({ post_id: item.id, token: props.token });
                                            }}
                                            get_comments_more={() => get_comments({
                                                post_id: item.id,
                                                more_loading: true,
                                                token: props.token
                                            })}
                                        />;
                                    }
                                }) : null}
                                {(posts.more_loading && posts.results.length > 0) || posts?.loading && <div className={styles.see_more}>
                                    <ClipLoader color="#464646" size={15} />
                                </div>}
                            </div>
                        </div>
                    </main>
                </MainLayout>
            </InfiniteScroll>
            {mybutton ? <Link href="#toTop"><a
                className={styles.to_top}
            ><span className='icon-arrow-left'></span>
            </a></Link> : null}
        </div>
    );
}

export default HomePage;
